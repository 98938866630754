'use strict';

angular.module('kljDigitalLibraryApp.admin')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('admin', {
                    url: '/admin',
                    templateUrl: 'app/admin/admin.html',
                    controller: 'AdminController',
                    controllerAs: 'admin',
                    authenticate: 'admin'
                });
        }
    ]);